<template>
    <styled-tooltip
        position="top"
        :max-width="status.tooltipWidth">
        <status-field :color="status.color">
            {{ status.label }}
        </status-field>
        <template
            v-if="status.tooltip === 'campaigns'"
            #content>
            <p>
                Budgeting is active for {{ campaignsLabel }}:
            </p>
            <table class="campaign-table">
                <tr>
                    <th>Name</th>
                    <th>Budget</th>
                    <th>Cap</th>
                    <th>End Date</th>
                </tr>
                <template
                    v-for="campaign in activeCampaigns">
                    <tr
                        :key="campaign.id"
                        class="campaign-row">
                        <td>
                            {{ campaign.name }}
                            <span v-if="isLifetimeBudget(campaign?.budget)">
                                (lifetime)
                            </span>
                        </td>
                        <td>
                            <span v-if="campaign.budget.level === BUDGET_LEVEL_CAMPAIGN">
                                {{ campaign.budget.daily_budget | numFormat(numberFormats.currency) }}
                            </span>
                        </td>
                        <td>
                            {{ campaign.budget.daily_spend_cap | numFormat(numberFormats.currency) }}
                        </td>
                        <td>
                            {{ getFormattedEndDate(campaign) }}
                        </td>
                    </tr>
                    <template
                        v-if="campaign.budget.level === BUDGET_LEVEL_ADSET">
                        <tr
                            v-for="adset in getActiveAdSetsFromCampaign(campaign)"
                            :key="adset.id"
                            class="adset-row">
                            <td>
                                {{ adset.name }}
                                <span v-if="isLifetimeBudget(adset?.budget)">
                                    (lifetime)
                                </span>
                            </td>
                            <td>
                                {{ adset.budget.daily_budget | numFormat(numberFormats.currency) }}
                            </td>
                            <td>N/A</td>
                            <td>
                                {{ getFormattedEndDate(campaign) }}
                            </td>
                        </tr>
                    </template>
                </template>
            </table>
        </template>
        <template
            v-else
            #content>
            {{ status.tooltip }}
        </template>
    </styled-tooltip>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import StatusField from '@/components/globals/StatusField';
import getNumberFormats from '@/helpers/numberFormats';
import { BUDGET_LEVEL_ADSET, BUDGET_LEVEL_CAMPAIGN, PLATFORM_STATUS_ACTIVE } from '@/helpers/globals';
import { isLifetimeBudget } from '@/apis/global/budgeting/getPlatformBudgeting';
import moment from 'moment';

const listFormatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction'
});

export default {
    components: {
        StyledTooltip,
        StatusField
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        platform: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            BUDGET_LEVEL_ADSET,
            BUDGET_LEVEL_CAMPAIGN,
            numberFormats: getNumberFormats(),
        };
    },
    computed: {
        activeCampaigns() {
            return this.item?.campaigns
                .filter(campaign => campaign.status === PLATFORM_STATUS_ACTIVE);
        },
        campaignsWithBudgetEnabled() {
            return this.activeCampaigns
                .filter(campaign => campaign.budget.level === BUDGET_LEVEL_CAMPAIGN);
        },
        adSetCount() {
            return this.item?.campaigns
                .filter(campaign => campaign.budget.level === BUDGET_LEVEL_ADSET && campaign.status === PLATFORM_STATUS_ACTIVE)
                .reduce((total, campaign) => {
                    return total + campaign.adsets.length;
                }, 0);
        },
        campaignsLabel() {
            const list = [];

            if (this.campaignsWithBudgetEnabled.length > 0) {
                list.push(`${this.campaignsWithBudgetEnabled.length} campaigns`);
            }

            if (this.adSetCount > 0) {
                list.push(`${this.adSetCount} ad sets`);
            }

            return listFormatter.format(list);
        },
        budgetValue() {
            return this.item.budgeting.monthlyBudget.value;
        },
        status() {
            if (!this.budgetValue) {
                return {
                    label: 'No budget',
                    color: 'yellow',
                    tooltipWidth: 300,
                    tooltip: 'A monthly budget goal must be set for this account for budgeting to work'
                };
            }

            if (this.budgetValue && !this.item.campaigns.length) {
                return {
                    label: 'No campaigns',
                    color: 'red',
                    tooltipWidth: 300,
                    tooltip: 'There are currently no active campaigns matching the filters to budget'
                };
            }

            return {
                label: this.campaignsLabel,
                color: 'green',
                tooltipWidth: 500,
                tooltip: 'campaigns'
            };

        }
    },
    methods: {
        isLifetimeBudget,
        getFormattedEndDate(object) {
            if (object.budget.end_date) {
                return moment(object.budget.end_date).format('YYYY-MM-DD');
            }

            return '-';
        },
        getActiveAdSetsFromCampaign(campaign) {
            return campaign.adsets
                .filter(adSet => adSet.budget.level === BUDGET_LEVEL_ADSET && adSet.status === PLATFORM_STATUS_ACTIVE);

        }
    }
};
</script>

<style lang="scss" scoped>
.campaign-table {
    tr {
        th {
            height: auto !important;
            font-size: 11px;
            padding-bottom: 6px !important;

            &:first-child {
                text-align: left;
            }
        }

        &:nth-child(even) {
            td {
                background-color: $alabaster-dark;
            }
        }

        td {
            font-size: 11px;
            padding: 8px !important;
            height: auto !important;

            &:last-child {
                text-align: center;
            }
        }
    }
}

.campaign-row {
    td:first-child {
        font-weight: bold;
    }
}

.adset-row {
    td:first-child {
        padding-left: 20px !important;
    }
}
</style>
